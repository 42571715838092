/**
 * @param url The URL string to check
 * @returns boolean True if the URL is absolute, false otherwise
 */
export function isAbsoluteUrl(url: string | null | undefined): boolean {
  // This checks for a protocol followed by "://" and then additional characters
  const absoluteUrlPattern = /^(?:[a-z+]+:)?\/\//i;

  return absoluteUrlPattern.test(url || '');
}
