/**
 * Compares the hosts of two URLs with optional protocol matching
 *
 * @param url1 The first URL to compare
 * @param url2 The second URL to compare
 * @param options Optional configuration for comparison
 * @returns boolean True if the hosts match according to the options
 */
export function doHostsMatch(
  url1: string,
  url2: string,
  options: {
    ignoreCase?: boolean;
    includePort?: boolean;
    compareProtocol?: boolean;
  } = {},
): boolean {
  try {
    // Create URL objects
    const parsedUrl1 = new URL(url1);
    const parsedUrl2 = new URL(url2);

    // Compare protocols if specified
    if (options.compareProtocol && parsedUrl1.protocol !== parsedUrl2.protocol) {
      return false;
    }

    // Extract hosts from the URLs
    let host1 = parsedUrl1.hostname;
    let host2 = parsedUrl2.hostname;

    // Include port in the host if specified
    if (options.includePort) {
      host1 = parsedUrl1.port ? `${host1}:${parsedUrl1.port}` : host1;
      host2 = parsedUrl2.port ? `${host2}:${parsedUrl2.port}` : host2;
    }

    // Convert to lowercase if case is ignored
    if (options.ignoreCase) {
      host1 = host1.toLowerCase();
      host2 = host2.toLowerCase();
    }

    // Compare the processed hosts
    return host1 === host2;
  } catch (error) {
    // If URLs can't be parsed, log error and return false
    // eslint-disable-next-line no-console
    console.warn('Error parsing URLs:', error);
    return false;
  }
}
